.btn {
  outline: none;
  /* outline: none; */
  background: var(--white-color);
  padding: 13px;
  /* font-size: 1.6rem; */
  border-radius: var(--sm-radius);
  border: 2px solid var(--black-color);
  color: var(--black-color);
  margin-block: 3rem;
  cursor: pointer;
  font-weight: 600;
  box-shadow: var(--boxShadow);
  transition: transform 0.3s ease-in-out;
}
.btn:hover,
.btn:visited,
.btn:focus,
.btn:target,
.btn:active {
  box-shadow: var(--boxShadow);
  background-color: transparent;
  transform: scale(1.1);
}
.reviewsWrapper {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-direction: column;
  align-items: center;
}
.reviewsWrapper .reviewsHeader {
  color: var(--black-color);
  margin: 0;
  padding: 0;
  font-size: clamp(2.5rem, 2.5vw, 5rem);
}
.reviewsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  align-items: stretch;
  padding-block: 1rem;
}
.reviewsContainer:has(li) {
  flex-grow: 1;
}

.reviewsLists {
  border: solid 2px var(--black-color);
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  width: 300px;
  border-radius: var(--sm-radius);
}
.reviewsLists:hover {
  white-space: normal;
  overflow: visible;
}
.reviewsName {
  color: var(--lunchSpan);
  padding-block: 1rem;
}
.reviewsStrong {
  border: none;
  font-size: 1.6rem;
  color: var(--black-color);
}
.reviewsSpan {
  color: yellow;
}
.reviewsText {
  color: var(--black-color);
  opacity: 0.8;
  padding: 1rem;
  font-size: 1.6rem;
}
.reviewsStar {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  padding-inline-start: 1rem;
  padding-bottom: 1rem;
}
