.lunchTime {
  display: flex;
  flex-direction: column;
}

.openHours {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  align-content: space-around;
  text-align: start;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  padding-top: 8px;
  display: flex;
  justify-content: center;
  /* font-size: 20px; */
  font-weight: 600;
}
.paddingHour {
  padding-right: 4px;
}
.hour {
  display: flex;
  justify-content: space-between;
  color: var(--lunchSpan);
}

.openHoursSpan {
  padding-inline: 4px;
}
.openHoursSpann {
  padding-inline: 24px;
}
.allergyStrong {
  border: none;
  rotate: none;
}
.allergyWarning {
  padding-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
.scrollWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerWrapper {
  max-width: var(--max-width);
  width: 100%;
  color: var(--black-color);
}
.headerWrapper h1 {
  display: flex;
  color: var(--headColor);
  justify-content: center;
  margin-top: 30vh;
  font-size: clamp(2.5em, 2.5vw, 5em);
}

.headerTop {
  align-content: center;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--black-color);
  margin-top: 19rem;
  border-radius: 10px 10px 0 0;
}
.headerPara {
  display: flex;
  padding: 2em;
  justify-content: center;
  font-size: clamp(1em, 1.8vw, 3em);
}
.linkWrapper img {
  background-color: var(--text-color);
  border-radius: 50%;
  margin-inline: 8px;
}
.dropdownContent {
  display: flex;
  height: fit-content;
  width: 80%;
  justify-content: space-around;
}

.subMenu {
  position: fixed;
  border: 1px solid var(--black-color);
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--background);
  padding: 8px;
  z-index: 99;
  height: auto;
  margin-top: 4rem;
  transition: all 0.3s ease; /* Add transition */
}

.subMenu.hidden {
  max-height: 0; /* Hide menu */
  padding: 0;
}

.subMenu ul {
  display: flex;
  width: 200px;
  flex-direction: column;
  /* height: 30vh; */
  height: 200px;
  text-align: justify;
  align-content: space-around;
  justify-content: space-around;
  flex-wrap: wrap;
  /* padding-block: 1rem; */
}
.subMenu a {
  display: flex;
  flex-direction: column;
  padding-block: 4px;
  transition: transform 0.4s ease-in-out;
}
.subMenu a:active,
.subMenu a:hover {
  transform: scale(1.1);
  text-decoration: underline;

  text-decoration-color: black;

  text-decoration-thickness: 2px;

  text-decoration-style: solid;

  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}
.listWrapper:hover .subMenu {
  cursor: pointer;
  padding: 8px;
  list-style: none;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}
.listWrapper {
  display: flex;
  box-shadow: var(--boxShadowOpactiy);
  border-radius: 4px;
  padding: 4px;
  list-style: none;
  /* height: -moz-fit-content;
  height: fit-content; */
  position: relative;
}
.imageCardBg {
  width: 100%;
  display: flex;
  /* flex: 1; */
  aspect-ratio: 16/9;
  /* padding: 0 2px 0 2px; */
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  height: auto;
  transition: transform 0.5s ease; /* Add transition property */
}

.imageCardBg img {
  /* height: auto;
  width: 100%; */
  border-radius: 10px 10px 0 0;
  z-index: -1;
}
.lunchSpan {
  display: flex;
  /* transition: 0.3s; */
  position: fixed;
  margin-top: 31rem;
  /* top: 20vh; */
  border-radius: var(--sm-radius);
  z-index: 998;
  /* backdrop-filter: blur(10px); */
  background-color: var(--background);
  box-shadow: var(--boxShadow);
  box-shadow: var(-moz-boxshadow);
  box-shadow: var(-webkit-boxshadow);
  align-items: center;
  justify-content: flex-start;
  /* padding-inline: 8px; */
}
.linkWrapper {
  /* position: relative; */
  display: flex;
  justify-self: center;
  align-content: space-around;
  width: var(--max-width);
  justify-content: space-between;
  flex-direction: row;
  align-items: stretch;
  padding: 1rem;
  scroll-behavior: smooth;
  overflow-y: hidden;
  overflow-x: scroll;
}
.linkWrapper button {
  font-size: 1.6em;
}
.lunchSpan:has(button) {
  max-width: 100%;
}
.lunchSpan button {
  display: flex;
  align-content: center;
  cursor: pointer;
  color: var(--black-color);
  /* font-size: 1.6em; */
}

.aboutArticle {
  font-size: 1em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--black-color);
  border-radius: 8px;
  margin: 1rem 0.5rem 0 0.5rem;
  box-shadow: inset var(--boxShadow);
}
.aboutArticle p {
  padding-bottom: 0em 1em 0.8em;
}
.spanDays {
  font-style: oblique;
  font-size: 3em;
  font-weight: 900;
  letter-spacing: 0.1em;
  transition: 0.3s;
  text-shadow: 1px 1px 0 grey, 1px 2px 0 grey, 1px 3px 0 grey, 1px 4px 0 grey,
    1px 5px 0 grey, 1px 6px 0 grey, 1px 7px 0 grey, 1px 8px 0 grey,
    5px 13px 15px black;
}
.lunchHour {
  font-style: oblique;
  /* font-weight: 600; */
  font-size: 2.5rem;
  background: var(--lunchSpan);
  border: 2px solid var(--black-color);
  margin-block: 1.8rem;
  border-radius: var(--sm-radius);
  width: -moz-fit-content;
  width: fit-content;
  color: var(--black-color);
  /* padding: 0.2em; */
  font-weight: 600;
  padding: 1.6rem;
}
.lunchTwo {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
.lunchTwo h2 {
  padding-block: 1.5em 0.8em;
  font-size: 1.6em;
}
.lunchTwo p {
  font-size: small;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
}
.lunchTwo h4 {
  margin-block: 1em;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  font-size: 1em;
}
.lunchTwo p {
  padding-block: 0.5em 0.8em;
  font-size: clamp(16px, 1.4vw, 24px);
  word-break: break-WORD;
}
.gluten h3 {
  font-size: clamp(1em, 2.4vw, 1.4em);
}
.glutenPara {
  color: var(--black-color);
  font-weight: 600;
  -webkit-text-decoration: underline var(--lunchSpan);
  text-decoration: underline var(--lunchSpan);
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
  padding-top: 1rem;
  font-size: clamp(1em, 1.2vw, 1.4em);
}
.gluten {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* line-height: 1.8rem; */
  padding: 1em;
  color: var(--dark-color);
}
.carousel {
  position: relative;
  width: 100%;
  height: 100%;
}
.carousel {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  overflow-x: scroll;
  scroll-snap-type: x proximity;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.slide.active {
  opacity: 1;
}
.paginationNumber {
  position: absolute;
  top: 90%;
  left: 50%;
  color: var(--black-color);
  z-index: 99;
  font-size: 2rem;
  font-weight: 600;
}
.prevButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  /* color: white; */
  font-size: 20px;
  cursor: pointer;
}
.prevButton:hover img,
.nextButton:hover img {
  opacity: 1;
  /* background-color: var(--lunchSpan); */
}

.prevButton {
  left: 10px;
}

.nextButton {
  right: 10px;
}

.price {
  font-size: 2.5rem;
  background: var(--lunchSpan);
  border: 2px solid var(--black-color);
  margin-block: 1.8rem;
  border-radius: var(--sm-radius);
  width: -moz-fit-content;
  width: fit-content;
  color: var(--black-color);
  font-weight: 600;
  padding: 1.6rem;
  box-shadow: var(--boxShadow);
}
.openTime {
  border: 1px solid var(--black-color);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  margin: 1rem 0.5rem 0 0.5rem;
  box-shadow: inset var(--boxShadow);
}
.openTime span {
  color: var(--black-color);
  /* padding-bottom: 0.5rem; */
  /* display: flex;
  justify-content: space-between;
  align-items: baseline; */
}

.openTime {
  color: var(--black-color);
  padding: 0.6em 0.6em;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
.openTime h4 {
  padding-block: 0.5em;
  font-size: 1.2em;
}
.openTime h5 {
  padding-block: 0.5em 0.8em;
  font-size: 1em;
}
.address {
  color: var(--black-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 0.8em;
  font-size: 1em;
  font-weight: 500;
}
@media (width > 701px) {
  .linkWrapper {
    overflow: hidden;
    /* background-color: red; */
  }
  .carousel {
    overflow: hidden;
  }
}
/* @media (min-width: 280px) {
  .lunchSpan {
    top: 18.5vh;
  }
}
@media (min-width: 360px) {
  .lunchSpan {
    top: 16.5vh;
  }
}
@media (min-width: 375px) {
  .lunchSpan {
    top: 18.5vh;
  }
}
@media (min-width: 390px) {
  .lunchSpan {
    top: 14.5vh;
  }
}

@media (min-width: 412px) {
  .lunchSpan {
    top: 13.2vh;
  }
}
@media (min-width: 413px) {
  .lunchSpan {
    top: 13.6vh;
  }
}
@media (min-width: 429px) {
  .lunchSpan {
    top: 13vh;
  }
}
@media (min-width: 540px) {
  .lunchSpan {
    top: 18vh;
  }
}
@media (min-width: 768px) {
  .lunchSpan {
    top: 12vh;
  }
}
@media (min-width: 820px) {
  .lunchSpan {
    top: 10.3vh;
  }
}
@media (min-width: 912px) {
  .lunchSpan {
    top: 9.5vh;
  }
}
@media (min-width: 1024px) {
  .lunchSpan {
    top: 10.3vh;
  }
} */
/* @media only screen and (orientation: landscape) and (min-aspect-ratio: 9/16) {
  .lunchSpan {
    top: 23vh;
  }
} */
