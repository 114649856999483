/* DeliverySection.module.css */

.deliverySection {
  display: flex;
  /* margin-top: 20px; */
  /* background-image: url(../public/delivery.svg); */
  background-size: cover;
  background-position: center;
  padding: 50px 0;
  color: var(--black-color);
  flex-direction: column;
  align-items: center;
}

.sectionTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: var(--black-color); /* Change color to match background */
}
.deliveryTime {
  display: flex;
  background-color: var(--background);
  border-radius: 8px;
  padding: 20px;
  min-width: 300px;
  box-shadow: var(--boxShadow);
  flex-direction: column;
  margin-bottom: 2rem;
}
.deliveryHour {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  font-size: 24px;
  font-weight: 800;
  border-bottom: 1px solid var(--black-color);
  padding-bottom: 1rem;
  color: var(--lunchSpan);
}
.deliveryOptions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.deliveryOption {
  background-color: var(--background); /* Add opacity to make text readable */
  border-radius: 8px;
  padding: 20px;
  min-width: 300px;
  /* border: 1px solid var(--black-color); */
  box-shadow: var(--boxShadow);
}

.optionTitle {
  font-size: 20px;
  margin-bottom: 10px;
  color: var(--black-color); /* Change color to match text */
}

.optionDetails {
  font-size: clamp(16px, 1.6vw, 32px);
  margin-bottom: 5px;
  color: var(--black-color);

  /* Change color to match text */
}
/* Apply the shaking animation to the SVG */
.animateSvg {
  animation: shake 1s linear forwards alternate; /* Adjust duration and timing function as needed */
}

/* Define the shaking animation */
@keyframes shake {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-100px);
  }
  50% {
    transform: translateY(100px);
  }
  75% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Apply the shaking animation to the SVG */
.animateSvg {
  animation: shake 1s linear forwards alternate; /* Adjust duration and timing function as needed */
}
/* Apply transition to all elements inside the SVG when moving forward */
.move-forward * {
  transition: transform 1s ease-in-out; /* Adjust duration and timing function as needed */
}

.svg {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.deliveryOption {
  cursor: pointer;
}
.svgContainer {
  width: 100%;
}
