.sharableLinkWrapper {
  display: flex;
  min-width: 300px;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 1rem;
  height: fit-content;
  padding-inline: 1.2rem;
}

.sharableLinkContainer {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.sharableLinkContainer h2 {
  padding-block: 3rem;
}
.sharableBtn {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  border-radius: var(--sm-radius);
  box-shadow: var(--boxShadow);
}

.sharableBtn:hover,
.sharableBtn:visited,
.sharableBtn:focus,
.sharableBtn:target,
.sharableBtn:active,
.sharableBtn:any-link {
  background-color: transparent;
  transform: scale(1.2);
}
